<template>
  <div>
    <div class="login">
      <v-container fill-height>
        <v-row
          justify="center"
          align="center"
          style="height: 90%"
          class="text-center"
        >
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="4"
            xl="3"
            class="d-flex justify-center align-center"
          >
            <div
              v-if="screen == 'login'"
              style="
                background: rgba(141, 141, 141, 0.1) !important;
                background-color: rgba(141, 141, 141, 0.1) !important;
                border-radius: 16px;
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(6.9px);
                -webkit-backdrop-filter: blur(6.9px);
                border: 1px solid rgba(255, 255, 255, 0.27);
                z-index: 10;
                max-width: 95%;
              "
            >
              <v-img
                max-width="60%"
                max-height="180px"
                contain
                :src="tenantBrand.logoUrl.landingLight"
                class="mt-4 mb-1 mx-auto"
              />
              <v-col
                cols="12"
                class="text-center my-0 py-0"
                v-if="tenantBrand.label == 'LØGLIVE'"
              >
                <div class="logo-text">
                  <span>-</span>
                  <span class="loading-text-words">L</span>
                  <span class="loading-text-words">Ø</span>
                  <span class="loading-text-words">G</span>
                  <span class="loading-text-words">L</span>
                  <span class="loading-text-words">I</span>
                  <span class="loading-text-words">V</span>
                  <span class="loading-text-words">E</span>
                  <span class="loading-text-words">-</span>
                </div>
              </v-col>
              <v-card-subtitle
                v-if="!$vuetify.breakpoint.mobile"
                class="my-0 py-0 mb-1"
              >
                <span style="color: white; font-size: 14px"
                  ><i>“Don't waste your time looking back.</i> <br /><i
                    >You're not going that way.”</i
                  >
                  <br />
                  <b>Ragnar Lodbrok</b></span
                >
              </v-card-subtitle>
              <v-card-text class="my-0 py-0">
                <v-text-field
                    dense
                    outlined
                    hide-details
                    class="my-1"
                    type="text"
                    clearable
                    autocomplete="username"
                  prepend-inner-icon="email"
                  v-model="model.emailAddress"
                  >
                    <template v-slot:label>
                      <span style="font-size: 13px"> Email Address </span>
                    </template>
                  </v-text-field>
                <!-- <v-text-field
                  label="Email Address"
                  type="text"
                  autocomplete="username"
                  outlined
                  dense
                  prepend-inner-icon="email"
                  v-model="model.emailAddress"
                  class="my-0 py-0"
                ></v-text-field> -->
                <v-text-field
                  autocomplete="new-password"
                  dense
                  outlined
                  prepend-inner-icon="lock"
                  v-model="model.password"
                  :type="passwordType"
                  :append-icon="
                    passwordType == 'text' ? 'visibility' : 'visibility_off'
                  "
                  @click:append="changePasswordVisibility"
                  class="my-1"
                  hide-details
                ><template v-slot:label>
                      <span style="font-size: 13px"> Password </span>
                    </template></v-text-field>
                <div class="my-0 py-0">
                  <v-row justify="center">
                    <v-col
                      cols="10"
                      sm="10"
                      md="8"
                      class="text-center pb-1 mb-1 mt-2 pt-2"
                    >
                      <v-btn
                        color="primary" small
                        :disabled="!model.emailAddress || !model.password"
                        class="white--text login-button"
                        @click="login"
                        :loading="loading"
                        >{{ !loading ? "Login" : "" }}</v-btn
                      >
                    </v-col>
                    <v-col cols="12" class="text-center py-1 my-1">
                      <a
                        class="forgot-password"
                        @click="screen = 'forgotPassword'"
                        style="cursor: pointer"
                        >Forgot Password</a
                      >
                    </v-col>
                    <v-col
                      cols="12"
                      class="text-center py-1 my-1 px-3"
                      :key="googleKey"
                    >
                      <v-row justify="center" class="my-2">
                        <div style="right: 0" id="buttonDiv"></div>
                      </v-row>
                    </v-col>

                    <!-- Checkbox for Terms and Conditions -->
                    <v-col cols="12" class="text-center py-1 my-1">
                      <!-- <label for="terms"> -->
                      <span class="termsparagraph">
                        By signing in you agree to LogLive
                        <a href="#" @click.prevent="showTerms = true"
                          >terms and conditions</a
                        >
                      </span>
                      <!-- </label> -->
                    </v-col>
                  </v-row>

                  <br />
                </div>
              </v-card-text>
            </div>
            <!-- <v-card dark :loading="loading"
              class="text-center glass-card" style="z-index: 1000">
            
            </v-card> -->

            <!-- Forgot Password Card -->
            <!-- <v-card light style="border-radius: 20px; margin: 0; padding: 0; width: 100%; background-color: white !important;"
              class="text-center" v-else-if="screen == 'forgotPassword'"> -->
            <div
              v-else-if="screen == 'forgotPassword'"
              style="
                background: rgba(141, 141, 141, 0.1) !important;
                background-color: rgba(141, 141, 141, 0.1) !important;
                border-radius: 16px;
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(6.9px);
                -webkit-backdrop-filter: blur(6.9px);
                border: 1px solid rgba(255, 255, 255, 0.27);
                z-index: 10;
                max-width: 95%;
              "
            >
              <!-- <v-img
                max-width="60%"
                max-height="200"
                contain
                :src="tenantBrand.logoUrl.landingLight"
                class="my-4 mx-auto"
              /> -->
              <v-img
                max-width="60%"
                max-height="180px"
                contain
                :src="tenantBrand.logoUrl.landingLight"
                class="mt-4 mb-1 mx-auto"
              />
              <v-col
                cols="12"
                class="text-center my-0 py-0"
                v-if="tenantBrand.label == 'LØGLIVE'"
              >
                <div class="logo-text">
                  <span>-</span>
                  <span class="loading-text-words">L</span>
                  <span class="loading-text-words">Ø</span>
                  <span class="loading-text-words">G</span>
                  <span class="loading-text-words">L</span>
                  <span class="loading-text-words">I</span>
                  <span class="loading-text-words">V</span>
                  <span class="loading-text-words">E</span>
                  <span class="loading-text-words">-</span>
                </div>
              </v-col>
              <v-card-title>Forgot Password</v-card-title>
              <v-card-text>
                <!-- <v-text-field
                  label="Email Address"
                  outlined
                  prepend-inner-icon="email"
                  v-model="model.emailAddress"
                ></v-text-field> -->
                <v-text-field
                    dense
                    outlined
                    hide-details
                    class="my-1"
                    type="text"
                    clearable
                    autocomplete="username"
                  prepend-inner-icon="email"
                  v-model="model.emailAddress"
                  >
                    <template v-slot:label>
                      <span style="font-size: 13px"> Email Address </span>
                    </template>
                  </v-text-field>
                <div class="my-0 py-0">
                  <v-row justify="center">
                    <v-col
                      cols="10"
                      sm="10"
                      md="8"
                      class="text-center pb-1 mb-1 mt-2 pt-2"
                    >
                      <v-btn
                        color="primary" small
                        :disabled="!model.emailAddress"
                        class="white--text login-button"
                        @click="forgotPassword"
                        :loading="loading"
                        >{{ !loading ? "Submit" : "" }}</v-btn
                      >
                    </v-col>
                    <v-col cols="12" class="text-center py-1 my-1">
                      <a
                        class="forgot-password"
                        @click="screen = 'login'"
                        style="cursor: pointer"
                        >Back to Sign in</a
                      >
                    </v-col>
                  </v-row>
                </div>
                <!-- <el-form class="login-form" ref="form" @submit.native.prevent="forgotPassword">
                  <el-form-item>
                    <v-btn color="primary" class="white--text login-button" @click="forgotPassword" :loading="loading">{{ !loading
                      ? "Submit" : "" }}</v-btn>
                  </el-form-item>
                </el-form> -->
              </v-card-text>

              <!-- </v-card>
              -->
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- This is to show the modal with the Terms and condition  -->
    <termsandconditions
      style="z-index: 1000"
      :showDialog.sync="showTerms"
      :content="termsContent"
      :version="termsVersion"
      @close="showTerms = false"
      @accept-terms="onTermsAccepted"
    />

    <v-dialog v-model="showUpdateAlert" max-width="500px">
      <v-card>
        <v-card-title>Terms and Conditions Updated</v-card-title>
        <v-card-text>
          Our Terms and Conditions have been updated. Please review the latest
          version.
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="showUpdatedTerms">Review Terms</v-btn>
          <v-btn @click="showUpdateAlert = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <svg :style="{ height: $vuetify.breakpoint.mobile ? '8vh' : '15vh' }" class="waves" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g class="parallax">
        <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(30, 30, 30, 0.65)" />
        <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(30, 30, 30, 0.50)" />
      </g>

    </svg> -->
  </div>
</template>

<script>
import Termsandconditions from "../TermsAndConditions/Termsandconditions.vue";
import { mapGetters } from "vuex";
// var google
export default {
  name: "login",
  data() {
    return {
      check: false,
      screen: "login",
      model: {
        emailAddress: "",
        password: "",
      },
      // V-Model for T'S and C's Checkbox
      accepted: false,
      showTerms: false,
      showUpdateAlert: false,
      // showTerms: false,
      termsContent: "",
      termsVersion: "",
      lastAgreedVersion: localStorage.getItem("lastAgreedVersion") || "",

      isInit: false,
      isSignIn: false,
      loading: false,
      google: undefined,
      googleKey: 1000,
      passwordType: "password",
      rules: {
        emailAddress: [
          {
            required: true,
            message: "Username is required",
            trigger: "blur",
          },
          {
            min: 4,
            message: "Username length should be at least 5 characters",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Password is required",
            trigger: "blur",
          },
          {
            min: 5,
            message: "Password length should be at least 5 characters",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    "$route.query.redirect": {
      immediate: true,
      handler(val) {
        console.log(val);
      },
    },
  },
  computed: {
    ...mapGetters(["tenantBrand"]),
  },
  async mounted() {
    this.screen = "login";
  },
  async created() {
    // window.onload = function () {
    setTimeout(() => {
      this.google = window.google;
      this.google.accounts.id.initialize({
        client_id:
          "999037980508-s1q1aft4n01cgltnv71lbne87eu1lnj9.apps.googleusercontent.com",
        itp_support: true,
        callback: this.handleCredentialResponse,
      });
    }, 500);

    this.screen = "login";
    let now = new Date();
    now = now.getTime();
    setTimeout(() => {
      this.google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        {
          theme: "outline",
          size: "large",
          shape: "pill",
          width: "250px",
          logo_alignment: "left",
        } // customization attributes
      );
      this.google.accounts.id.prompt();
      console.log("Render google button complete");
    }, 2000);
    if (
      this.$store.state.authState == "signedin" &&
      now < this.$store.state.tokenExpiry
    ) {
      this.validateToken();
    } else {
      await this.$store.dispatch("setAuthState", "signedout");
      this.$store.dispatch("setToken", null);
      this.$store.dispatch("setUser", null);
      this.$store.dispatch("setTokenExpiry", null);
      // this.$store.dispatch("setAuthState", null);
      this.$store.dispatch("setOrganisations", null);
      this.$store.dispatch("setCurrentOrg", null);
      this.$store.dispatch("setOrgToken", null);
    }

    // }
    // this.loading = false
  },

  methods: {
    handleCredentialResponse(response) {
      this.authGoogleUserData(response.credential);
    },
    async validateToken() {
      try {
        this.loading = true;
        let result = await this.$API.validateToken();
        // console.log('Token Valid', result)
        if (result.valid) {
          await this.$store.dispatch(
            "setToken",
            result.token.type + result.token.accessToken
          );
          await this.$store.dispatch("setTokenExpiry", result.token.expiry);
          await this.$store.dispatch("setAuthState", "signedin");
          setTimeout(() => {
            this.$router.push({
              path: "/landing",
            });
          }, 1000);
          this.loading = false;
        } else {
          this.loading = false;
          await this.$store.dispatch("setAuthState", "signedout");
          this.$store.dispatch("setToken", null);
          this.$store.dispatch("setUser", null);
          this.$store.dispatch("setTokenExpiry", null);
          // this.$store.dispatch("setAuthState", null);
          this.$store.dispatch("setOrganisations", null);
          this.$store.dispatch("setCurrentOrg", null);
          this.$store.dispatch("setOrgToken", null);
        }
      } catch (e) {
        this.loading = false;
        await this.$store.dispatch("setAuthState", "signedout");
        this.$store.dispatch("setToken", null);
        this.$store.dispatch("setUser", null);
        this.$store.dispatch("setTokenExpiry", null);
        // this.$store.dispatch("setAuthState", null);
        this.$store.dispatch("setOrganisations", null);
        this.$store.dispatch("setCurrentOrg", null);
        this.$store.dispatch("setOrgToken", null);
      }
    },
    changePasswordVisibility() {
      if (this.passwordType == "text") {
        this.passwordType = "password";
      } else {
        this.passwordType = "text";
      }
    },
    async forgotPassword() {
      try {
        let result = await this.$API.forgotPassword({
          emailAddress: this.model.emailAddress,
        });
        if (result && result.code == 200) {
          this.$message.success(
            "A password reset link has been sent to your email address"
          );
          this.screen = "login";
        } else {
          this.$message.error(result.message);
        }
      } catch (e) {
        this.$message.error(
          "An error occurred while trying to reset your password"
        );
      }
    },
    async handleClickGetAuth() {
      try {
        const authCode = await this.$gAuth.getAuthCode();
        console.log(authCode);
      } catch (error) {
        this.$message.error("An error occurred when trying to sign in.");
      }
    },
    async handleClickSignIn() {
      this.google.accounts.id.initialize({
        client_id:
          "999037980508-s1q1aft4n01cgltnv71lbne87eu1lnj9.apps.googleusercontent.com",
        callback: this.handleCredentialResponse,
      });
      this.google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        { theme: "outline", size: "large" } // customization attributes
      );
      this.google.accounts.id.prompt();
      // try {
      //   this.loading = true;
      //   const googleUser = await this.$gAuth.signIn();
      //   let keys = Object.keys(googleUser);
      //   for (let i = 0; i < keys.length; i++) {
      //     let objKeys = Object.keys(googleUser[keys[i]]);
      //     if (objKeys.includes("access_token")) {
      //       this.authGoogleUserData(googleUser[keys[i]]["access_token"]);
      //     }
      //   }
      //   this.isSignIn = this.$gAuth.isAuthorized;
      //   // this.loading = false
      // } catch (error) {
      //   this.loading = false;
      //   this.$message.error("An error occurred when trying to sign in.");
      //   return null;
      // }
    },

    async handleClickSignOut() {
      try {
        await this.$gAuth.signOut();
        this.isSignIn = this.$gAuth.isAuthorized;
      } catch (error) {
        // On fail do something
      }
    },

    //Fetch the latest terms and conditions from controller

    async fetchLatestTerms() {
      try {
        const response = await this.$API.getTerms({
          organisationId: this.$store.state.currentOrg.id,
        });
        const latestContent = response.data.content;
        const latestVersion = response.data.version;

        // Compare the latest version with the one stored locally
        if (this.lastAgreedVersion !== latestVersion) {
          this.showUpdateAlert = true;
          this.termsContent = latestContent;
          this.termsVersion = latestVersion;
        }
      } catch (error) {
        console.error("Error fetching terms:", error);
      }
    },
    showUpdatedTerms() {
      this.showUpdateAlert = false;
      this.showTerms = true;
    },
    onTermsAccepted(version) {
      // Store the new agreed version in local storage
      localStorage.setItem("lastAgreedVersion", version);
      this.showTerms = false;
    },
    async created() {
      await this.fetchLatestTerms();
    },

    async login() {
      // let valid = await this.$refs.form.validate();
      // if (!valid) {
      //   return;
      // }
      this.loading = true;
      let access = await this.$API.systemLogin(this.model);
      if (access.code == 200) {
        this.$message.success(access.message);
        await this.$store.dispatch(
          "setToken",
          access.token.type + access.token.accessToken
        );
        await this.$store.dispatch("setTokenExpiry", access.token.expiry);
        await this.$store.dispatch("setUser", access.user);
        await this.$store.dispatch("setAuthState", "signedin");
        // this.$router.push({
        //     path: '/landing'
        // })
        setTimeout(() => {
          this.$router.push({
            path: "/landing",
          });
        }, 500);
      } else {
        this.$message.error(access.message);
      }
      this.loading = false;
    },

    async authGoogleUserData(access_token) {
      this.loading = true;
      let access = await this.$API.authGoogleUser({
        access_token: access_token,
      });
      if (access.code == 200) {
        this.$message.success(access.message);
        await this.$store.dispatch(
          "setToken",
          access.token.type + access.token.accessToken
        );
        await this.$store.dispatch("setTokenExpiry", access.token.expiry);
        await this.$store.dispatch("setUser", access.user);
        await this.$store.dispatch("setAuthState", "signedin");
        setTimeout(() => {
          this.$router.push({
            path: "/landing",
          });
          // this.loading = false;
        }, 500);
      } else {
        this.$message.error(access.message);
        this.loading = false;
      }
    },
    onSuccess(googleUser) {
      console.log("Logged in as: " + googleUser.getBasicProfile().getName());
    },
    onFailure(error) {
      console.log(error);
    },

    onSignIn(googleUser) {
      var profile = googleUser.getBasicProfile();
      console.log("ID: " + profile.getId()); // Do not send to your backend! Use an ID token instead.
      console.log("Name: " + profile.getName());
      console.log("Image URL: " + profile.getImageUrl());
      console.log("Email: " + profile.getEmail()); // This is null if the 'email' scope is not present.
    },
  },
  components: {
    Termsandconditions,
  },
};
</script>

<style lang="scss">
.glass-card {
  background: rgba(141, 141, 141, 0.05) !important;
  border-radius: 15px;
  backdrop-filter: blur(16.8px);
  -webkit-backdrop-filter: blur(16.8px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(141, 141, 141, 0.18);
}

.termsparagraph {
  font-size: small;
}

.terms-content {
  max-height: 500px;
  overflow-y: auto;
  padding: 16px;
  font-size: 14px;
  line-height: 1.6;
  color: #333;
}
.logo-text {
  top: 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 26px;
  font-weight: bold;
  span {
    display: inline-block;
    margin: 0 5px;
    color: var(--v-primaryText-base);
    font-family: Cinzel, serif;
  }
}

.login {
  flex: 1;
  height: 100dvh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-button {
  width: 100%;
  margin-top: 10px;
}

.login-form {
  width: 100%;
  align-items: center;
  margin: 0;
  padding: 10px;
  /* border-radius: 25px; */
}
html,
body {
  margin: 0;
  padding: 0;
}
body {
  background: #000;
  background: rgba(0, 0, 0, 1);
  overflow-x: hidden;
}
.forgot-password {
  font-size: 14px;
  margin-top: 10px;
}
.fogwrapper {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
  filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
}
#foglayer_01,
#foglayer_02,
#foglayer_03 {
  height: 100%;
  position: absolute;
  width: 200%;
}
#foglayer_01 .image01,
#foglayer_01 .image02,
#foglayer_02 .image01,
#foglayer_02 .image02,
#foglayer_03 .image01,
#foglayer_03 .image02 {
  float: left;
  height: 100%;
  width: 50%;
}
#foglayer_01 {
  -webkit-animation: foglayer_01_opacity 10s linear infinite,
    foglayer_moveme 15s linear infinite;
  -moz-animation: foglayer_01_opacity 10s linear infinite,
    foglayer_moveme 15s linear infinite;
  animation: foglayer_01_opacity 10s linear infinite,
    foglayer_moveme 15s linear infinite;
}
#foglayer_02,
#foglayer_03 {
  -webkit-animation: foglayer_02_opacity 21s linear infinite,
    foglayer_moveme 13s linear infinite;
  -moz-animation: foglayer_02_opacity 21s linear infinite,
    foglayer_moveme 13s linear infinite;
  animation: foglayer_02_opacity 21s linear infinite,
    foglayer_moveme 13s linear infinite;
}

/* ---------- Moving Fog ---------- */
/*
  'size: cover' || 'size: 100%'; results remain the same
  'attachment: scroll' can be added or removed; results remain the same
  'attachment: fixed' causing unexpected results in Chrome
  'repeat-x' || 'no-repeat'; results remain the same
*/
#foglayer_01 .image01,
#foglayer_01 .image02 {
  background: url("https://raw.githubusercontent.com/danielstuart14/CSS_FOG_ANIMATION/master/fog1.png")
    center center/cover no-repeat transparent;
}
#foglayer_02 .image01,
#foglayer_02 .image02,
#foglayer_03 .image01,
#foglayer_03 .image02 {
  background: url("https://raw.githubusercontent.com/danielstuart14/CSS_FOG_ANIMATION/master/fog2.png")
    center center/cover no-repeat transparent;
}

/* ---------- Keyframe Layer 1 ---------- */
@-webkit-keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}
@-moz-keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}
@-o-keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}
/* ---------- Keyframe Layer 2 ---------- */
@-webkit-keyframes foglayer_02_opacity {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}
@-moz-keyframes foglayer_02_opacity {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}
@-o-keyframes foglayer_02_opacity {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes foglayer_02_opacity {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}
/* ---------- Keyframe Layer 3 ---------- */
@-webkit-keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
@-moz-keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
@-o-keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
@keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
/* ---------- Keyframe moveMe ---------- */
@-webkit-keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@-moz-keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@-o-keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

@media only screen and (min-width: 280px) and (max-width: 767px) {
  #foglayer_01 .image01,
  #foglayer_01 .image02,
  #foglayer_02 .image01,
  #foglayer_02 .image02,
  #foglayer_03 .image01,
  #foglayer_03 .image02 {
    width: 100%;
  }
}
.logo {
  display: inline-block;
  margin: 0 5px;
  font-size: 28px;
  font-family: Cinzel, serif;
}

$teal: var(--v-primary-base);

.el-button--primary {
  background: $teal;
  border-color: $teal;

  &:hover,
  &.active,
  &:focus {
    // background: lighten($teal, 7);
    // border-color: lighten($teal, 7);
  }
}

.login .el-input__inner:hover {
  border-color: $teal;
}

.login .el-input__prefix {
  background: rgb(238, 237, 234);
  left: 0;
  height: calc(100% - 2px);
  left: 1px;
  top: 1px;
  border-radius: 3px;

  .el-input__icon {
    width: 30px;
  }
}

.login .el-input input {
  padding-left: 35px;
}

.login .el-card {
  padding-top: 0;
  padding-bottom: 30px;
}

a {
  color: $teal;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    // color: lighten($teal, 7);
  }
}

.login .el-card {
  border-radius: 25px;
  max-width: 340px;
  display: flex;
  justify-content: center;
}

.waves {
  position: relative;
  width: 100vw;
  margin-bottom: -7px;
  /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

.content {
  position: relative;
  height: 20vh;
  text-align: center;
  background-color: #fac861;
}

/* Animation */
.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
  animation-delay: -1s;
  animation-duration: 10s;
}

.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 15s;
}

.parallax > use:nth-child(3) {
  animation-delay: -5s;
  animation-duration: 18s;
}

.parallax > use:nth-child(4) {
  animation-delay: -7s;
  animation-duration: 22s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

#ship {
  animation: swing 2s infinite ease;
  color: #fff;
  text-align: center;
  transform-origin: bottom center;
  position: relative;
  height: 100px;
  line-height: 100px;
  width: 100px;
  bottom: 50px;
  z-index: 9999;
}

.boat {
  animation: boat 3s linear infinite;
  background-repeat: no-repeat;
  bottom: 5vh;
  height: 145px;
  left: 50%;
  position: absolute;
  width: 400px;
  z-index: 99999;
}

@keyframes swing {
  0% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(-10deg);
  }
}

@keyframes boat {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-3deg);
  }

  100% {
    transform: rotate(0);
  }
}
</style>
